import { EditableRichTextEditor, RichTextEditorProps } from '@fcamna/aem-library';
import { fpds } from '@fcamna/shared-component-foundations';
import styled from 'styled-components';

const StyledText = styled(EditableRichTextEditor)`
  &.text-content {
    h2 {
      margin-top: 50px;
      align-self: center;
      margin-bottom: 30px;
    }

    p,
    li {
      font-size: 18px;
    }

    a {
      text-decoration: none;
      color: ${fpds.primitive.color.skyview[1000].value};
    }
  }
`;

export const Text = (props: Partial<RichTextEditorProps> & { name: string }) => {
  return <StyledText {...props} removeDefaultStyles={true} className={`text-content ${props.className}`} />;
};

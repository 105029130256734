import { AEMPage, EditableButton } from '@fcamna/aem-library';
import { useRef, useState } from 'react';
import styled from 'styled-components';

import { copyAEMPage, toAEMPagePath } from '../../../../utils/api';
import withAnimatedNavigation, { AnimatedNavigationProps } from '../../../../utils/withAnimatedNavigation';
import { getAEMHost } from '../../config';
import { EditableRegexInput } from '../EditableInput';
import { Page, PageContent, PageFooter, PageHeader } from '../Page';
import { Text } from '../wrappedLibraryComponents/Text';

const AssemblerGuide = styled.div`
  display: flex;
  flex-direction: column;
  margin: 50px auto;
  align-items: center;
  max-width: 1440px;

  .productName {
    margin: 100px 0 100px;
    width: 500px;
    justify-self: center;

    label {
      font-size: 18px;
    }
  }

  .subheader {
    width: 900px;
    margin-top: 30px;

    p {
      font-size: 18px;
    }
  }

  .continueBtn {
    margin-top: 50px;
    display: flex;
    justify-content: center;
  }
`;

const TextFullWidth = styled(Text)`
  width: 100%;
`;
const Intro = (props: AnimatedNavigationProps) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const productNameRef = useRef<HTMLInputElement | null>(null);
  return (
    <AssemblerGuide>
      <AEMPage pagePath={`${process.env.AEM_CONTENT_BASE}/embed-dynamic-pages`} aemHost={getAEMHost()}>
        <Page>
          <PageHeader>
            <Text name="embed-header" />
          </PageHeader>
          <PageContent style={{ alignItems: 'center', width: '1000px' }}>
            <TextFullWidth
              name="test"
              replacements={{
                '/new-page.html': `/dynamic-page`
              }}
              style={{ width: '100%' }}
            />
            <EditableRegexInput
              name="productName"
              className="productName"
              regex={/^[a-zA-Z\-\s0-9]+$/}
              onValidChange={(v: boolean) => setIsDisabled(!v)}
              inputRef={productNameRef}
            />
          </PageContent>
          <PageFooter>
            <EditableButton
              className="continueBtn"
              isDisabled={isDisabled}
              onClick={() => {
                const productName = productNameRef.current?.value.replace(/\s/g, '-').toLowerCase() || '';
                setIsDisabled(true);
                copyAEMPage(`${process.env.AEM_REFERENCE_PAGE}${process.env.SAMPLE_ASSEMBLER_MFE_PAGE}`, toAEMPagePath({ productName }), {
                  remoteSPAUrl: process.env.ASSEMBLER_URL
                })
                  .then((pagePath) => {
                    props.navigateForward!('create-page-tile', {
                      pagePath,
                      productName
                    });
                  })
                  .catch((e) => {
                    console.log(e);
                  })
                  .finally(() => setIsDisabled(false));
              }}
            />
          </PageFooter>
        </Page>
      </AEMPage>
    </AssemblerGuide>
  );
};

export default withAnimatedNavigation(Intro);

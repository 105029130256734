import { Icon, IconButton } from '@fcamna/react-library';
import { Mode, ThemeName, ThemeProvider } from '@fcamna/shared-component-foundations';
import { CSSProperties, useState } from 'react';
import { Prism as SyntaxHighlighter, SyntaxHighlighterProps } from 'react-syntax-highlighter';
import { nightOwl } from 'react-syntax-highlighter/dist/esm/styles/prism';
import styled from 'styled-components';

const StyledCode = styled.div`
  background-color: #00095b;
  color: white;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  cursor: text;
  position: relative;
  font-size: 16px;
  white-space: preserve;
  max-width: 1440px;

  .code-block {
    padding: 25px 0 25px 35px;
  }

  .command-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .copy-button-container {
    border: none;
    border-radius: 10px;
    align-self: flex-start;
    margin: 3px;
  }

  .token {
    white-space: pre-wrap;
  }
`;

const Code = (props: {
  command: string;
  maxWidth?: number | string;
  hideCopyButton?: boolean;
  className?: string;
  style?: CSSProperties;
  codeBlockProps?: Partial<SyntaxHighlighterProps>;
}) => {
  const [icon, setIcon] = useState(<Icon name="copy" color="white" />);

  return (
    <StyledCode className={props.className} style={{ ...props.style, maxWidth: props.maxWidth }}>
      <div className="code-block" style={{ overflow: 'auto', flexGrow: 1 }}>
        <div className="command-container">
          <SyntaxHighlighter
            language={'bash'}
            style={nightOwl}
            wrapLines={true}
            wrapLongLines={true}
            {...props.codeBlockProps}
            customStyle={{ backgroundColor: '#00095b', padding: 0, border: 'none', boxShadow: 'none' }}>
            {props.command}
          </SyntaxHighlighter>
        </div>
      </div>
      <ThemeProvider themeName={ThemeName.FORD_PRO} mode={Mode.DARK_MODE}>
        {!props.hideCopyButton && (
          <div className="copy-button-container">
            <IconButton
              size="medium"
              variant="tertiary"
              icon={icon}
              onClick={() => {
                navigator.clipboard.writeText(props.command);
                setIcon(<Icon name="checkmark" size="xl" color="#00e83e" />);
              }}
            />
          </div>
        )}
      </ThemeProvider>
    </StyledCode>
  );
};

export default Code;

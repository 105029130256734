import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { EditableButton } from '@fcamna/aem-library';
import { Spinner } from '@fcamna/react-library';
import { fpds } from '@fcamna/shared-component-foundations';
import { useMemo, useRef, useState } from 'react';

import { copyAEMPage, toAEMPagePath } from '../../../utils/api';
import { checkAEMPageExists, debounceV2 } from '../../../utils/debounce';
import { EditableRegexInput, ErrorMessage } from './EditableInput';

const debounce300ms = debounceV2(300);

enum PageGeneratorState {
  DEFAULT,
  PAGE_CREATING,
  PAGE_EXISTED,
  PAGE_CREATED
}

const PageGenerator = (props: {
  productName: string;
  name: string;
  sourcePage?: string;
  spaURL?: string;
  rootDirectory?: string;
  onCreated?: (pagePath?: string, pageName?: string) => void;
}) => {
  const isInEditor = useMemo(() => AuthoringUtils.isInEditor(), []);
  const [isButtonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [state, setState] = useState<PageGeneratorState>(PageGeneratorState.DEFAULT);

  const pageNameRef = useRef<HTMLInputElement>(null);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', margin: '35px 0' }}>
      <div style={{ display: 'flex', gap: 30, alignItems: 'start', alignSelf: 'start' }}>
        <EditableRegexInput
          style={{ marginTop: 4 }}
          regex={/^$|^[a-zA-Z\-\s0-9]+$/}
          inputRef={pageNameRef}
          width={250}
          hideLabel={true}
          onValidChange={(valid: boolean) => {
            const pageName = pageNameRef.current?.value.replace(/\s/g, '-').toLowerCase();
            setButtonDisabled(true);
            if (valid && pageName) {
              const pagePath = toAEMPagePath({ productName: props.productName, pageName, rootDirectory: props.rootDirectory });
              debounce300ms(() =>
                checkAEMPageExists(pagePath).then((isExists) => {
                  setState(isExists ? PageGeneratorState.PAGE_EXISTED : PageGeneratorState.DEFAULT);
                  if (pageNameRef.current?.value) setButtonDisabled(isExists);
                })
              );
            } else {
              setState(PageGeneratorState.DEFAULT);
            }
          }}
          name={`${props.name}-input`}
        />
        {state === PageGeneratorState.PAGE_CREATING && <Spinner style={{ alignSelf: 'center' }} size="medium" />}

        {state !== PageGeneratorState.PAGE_CREATING && (
          <EditableButton
            name={`${props.name}-btn-create`}
            isDisabled={isButtonDisabled}
            onClick={() => {
              setState(PageGeneratorState.PAGE_CREATING);
              const pageName = pageNameRef.current?.value.replace(/\s/g, '-').toLowerCase();
              copyAEMPage(
                `${process.env.AEM_REFERENCE_PAGE}${props.sourcePage || process.env.SAMPLE_ASSEMBLER_MFE_PAGE}`,
                toAEMPagePath({ productName: props.productName, pageName, rootDirectory: props.rootDirectory || 'root' }),
                {
                  remoteSPAUrl: props.spaURL || process.env.ASSEMBLER_URL,
                  'jcr:title': pageName as string
                }
              ).then((pagePath) => {
                setState(PageGeneratorState.PAGE_CREATED);
                setButtonDisabled(true);
                if (props.onCreated) props.onCreated(pagePath, pageName);
              });
            }}
          />
        )}
      </div>

      {(state === PageGeneratorState.PAGE_EXISTED || isInEditor) && <ErrorMessage name={`${props.name}-exists-message`} />}
      {(state === PageGeneratorState.PAGE_CREATED || isInEditor) && (
        <ErrorMessage name={`${props.name}-created-message`} color={fpds.primitive.color.green[1000].value} />
      )}
    </div>
  );
};

export default PageGenerator;

import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { AEMPage, EditableButton, EditableRadioGroup } from '@fcamna/aem-library';
import { useMemo, useState } from 'react';
import styled from 'styled-components';

import withAnimatedNavigation, { AnimatedNavigationProps } from '../../../../utils/withAnimatedNavigation';
import { getAEMHost } from '../../config';
import { Page, PageContent, PageFooter, PageHeader } from '../Page';
import { Text } from '../wrappedLibraryComponents/Text';

const StyledRadio = styled(EditableRadioGroup)`
  .RadioGroup {
    margin-left: 15px;
  }
  .RadioButton__label {
    font-size: 18px;
    font-weight: bolder;
  }
  .Label__text {
    font-size: 18px;
  }
`;

const ProvisioningAssembler = (props: AnimatedNavigationProps) => {
  const [userSelection, setUserSelection] = useState(false);
  const isInEditor = useMemo(() => AuthoringUtils.isInEditor(), []);

  return (
    <AEMPage pagePath={`${process.env.AEM_CONTENT_BASE}/provisioning-assembler`} aemHost={getAEMHost()}>
      <Page>
        <PageHeader>
          <Text name="title" />
        </PageHeader>
        <PageContent>
          <Text name="intro" style={{ width: '100%' }} />
          <Text name="prerequisite" replacements={{ 'http://createpagelink.com': '/new-page' }} />
          <StyledRadio name="intakeform" onChange={(value: string) => setUserSelection(value === 'true')} />
        </PageContent>
        <PageFooter>
          {(!userSelection || isInEditor) && (
            <EditableButton name="not-completed" onClick={() => props.navigateForward!(`/provisioning-assembler/intake-request`)} />
          )}
          {(userSelection || isInEditor) && (
            <EditableButton name="completed" onClick={() => props.navigateForward!(`/provisioning-assembler/deploying-assembler`)} />
          )}
        </PageFooter>
      </Page>
    </AEMPage>
  );
};

export default withAnimatedNavigation(ProvisioningAssembler);

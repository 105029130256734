import { AEMPage, EditableButton, EditableCustomImage } from '@fcamna/aem-library';

import withAnimatedNavigation, { AnimatedNavigationProps } from '../../../../utils/withAnimatedNavigation';
import { getAEMHost } from '../../config';
import { Page, PageContent, PageFooter, PageHeader } from '../Page';
import { Text } from '../wrappedLibraryComponents/Text';

const FurtherCustomizations = (props: AnimatedNavigationProps) => {
  return (
    <AEMPage pagePath={`${process.env.AEM_CONTENT_BASE}/provisioning-assembler/further-customizations`} aemHost={getAEMHost()}>
      <Page>
        <PageHeader>
          <Text name="head" />
        </PageHeader>
        <PageContent>
          <Text
            name="par1"
            replacements={{
              'http://gettingtoknow.com': '/assembler-quickstart'
            }}
          />
          <EditableCustomImage name="proxy-image" style={{ alignSelf: 'center', margin: '30px 0 30px 0' }} />
        </PageContent>
        <PageFooter>
          <EditableButton name="back-to-home" onClick={() => props.navigateForward!('/')} />
        </PageFooter>
      </Page>
    </AEMPage>
  );
};

export default withAnimatedNavigation(FurtherCustomizations);

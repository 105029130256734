import { AEMPage, EditableButton, EditableCustomImage } from '@fcamna/aem-library';

import withAnimatedNavigation from '../../../../utils/withAnimatedNavigation';
import { getAEMHost } from '../../config';
import { Page, PageContent, PageFooter, PageHeader } from '../Page';
import { Text } from '../wrappedLibraryComponents/Text';

const IntakeRequest = () => {
  return (
    <AEMPage pagePath={`${process.env.AEM_CONTENT_BASE}/provisioning-assembler/intake-request`} aemHost={getAEMHost()}>
      <Page>
        <PageHeader>
          <Text name="header" />
        </PageHeader>
        <PageContent>
          <Text name="p1" />
          <EditableCustomImage name="gcp-image" style={{ alignSelf: 'center' }} />
          <Text name="p2" />
        </PageContent>
        <PageFooter>
          <EditableButton name="continue" onClick={() => window.open('https://forms.office.com/r/EiVjAebzfP')} />
        </PageFooter>
      </Page>
    </AEMPage>
  );
};

export default withAnimatedNavigation(IntakeRequest);

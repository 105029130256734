import { AEMPage, EditableButton } from '@fcamna/aem-library';
import { useRef, useState } from 'react';
import styled from 'styled-components';

import { copyAEMPage, toAEMPagePath } from '../../../../utils/api';
import withAnimatedNavigation, { AnimatedNavigationProps } from '../../../../utils/withAnimatedNavigation';
import { getAEMHost } from '../../config';
import { EditableRegexInput } from '../EditableInput';
import { Page, PageContent, PageFooter, PageHeader } from '../Page';
import { Text } from '../wrappedLibraryComponents/Text';

const StyledRegex = styled.div`
  .productName {
    margin: 20px 0 100px;
    width: 500px;

    label {
      font-size: 18px;
    }
  }
`;

function TransactionalPage(props: AnimatedNavigationProps) {
  const [isDisabled, setIsDisabled] = useState(true);

  const productNameRef = useRef<HTMLInputElement>(null);
  return (
    <AEMPage pagePath={`${process.env.AEM_CONTENT_BASE}/dynamic-page`} aemHost={getAEMHost()}>
      <Page>
        <PageHeader>
          <Text name="title" />
        </PageHeader>
        <PageContent>
          <div style={{ width: 900, margin: '0 auto' }}>
            <Text name="content" />
          </div>
          <div
            style={{
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%'
            }}>
            <StyledRegex>
              <EditableRegexInput
                name="productName"
                className="productName"
                regex={/^[a-zA-Z\-\s0-9]+$/}
                onValidChange={(v: boolean) => setIsDisabled(!v)}
                inputRef={productNameRef}
              />
            </StyledRegex>
          </div>
        </PageContent>
        <PageFooter>
          <EditableButton
            className="continueBtn"
            isDisabled={isDisabled}
            onClick={() => {
              const productName = productNameRef.current?.value.replace(/\s/g, '-').toLowerCase() || '';
              setIsDisabled(true);
              copyAEMPage(`${process.env.AEM_REFERENCE_PAGE}${process.env.SAMPLE_TILE_PAGE}`, toAEMPagePath({ productName }), {
                remoteSPAUrl: process.env.TILE_SPA_URL
              })
                .then((pagePath) => {
                  props.navigateForward!('/dynamic-page/app-name', {
                    pagePath,
                    productName
                  });
                })
                .catch((e) => {
                  console.log(e);
                })
                .finally(() => setIsDisabled(false));
            }}
          />
        </PageFooter>
      </Page>
    </AEMPage>
  );
}

export default withAnimatedNavigation(TransactionalPage);

import { AEMPage, EditableButton } from '@fcamna/aem-library';
import { fpds } from '@fcamna/shared-component-foundations';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import withAnimatedNavigation, { AnimatedNavigationProps } from '../../../utils/withAnimatedNavigation';
import Card from '../components/Card';
import { Page, PageContent } from '../components/Page';
import { Text } from '../components/wrappedLibraryComponents/Text';
import { getAEMHost } from '../config';

const StyledHeader = styled.div`
  display: flex;
  position: relative;
  left: 0;
  height: 225px;
  background-color: ${fpds.primitive.color.twilight[1000].value};
  justify-content: center;
  align-items: center;
  width: 100vw;

  h1 {
    color: white;
  }
`;

const AccessLinkContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 50px;
  margin-top: 50px;

  .access-link {
    width: 450px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    text-align: center;
  }
`;

const StyledGrid = styled.div`
  width: 100%;
  display: grid;
  gap: 50px;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 50px;

  .last-card {
    width: calc((100% - 50px) / 2);
    grid-column: 1 / span 2;
    justify-self: center;
  }
`;

const CardCell = styled.div`
  width: 100%;
  min-width: 400px;
  display: flex;
  justify-content: center;
`;

const FilterContainer = styled.div`
  display: flex;
  width: fit-content;
  gap: 5px;
  margin-bottom: 15px;

  .instruction-text {
    display: flex;
    align-items: center;
    margin-bottom: 3px;
  }

  .bold {
    font-weight: bold;
  }
`;

const Home = (props: AnimatedNavigationProps) => {
  const tutorialCards = [
    {
      name: 'view-ucl',
      disabled: false,
      onClick: () =>
        window.open('https://ford-innersource.github.io/universal-component-library/?path=/docs/getting-started--documentation'),
      tags: ['developer', 'author']
    },
    {
      name: 'change-ucl',
      disabled: false,
      onClick: () => window.open('https://github.com/ford-innersource/universal-component-library'),
      tags: ['developer']
    },
    {
      name: 'basics',
      disabled: false,
      onClick: () => window.open('https://www.eesewiki.ford.com/display/FMCC/How+to+create+a+new+page'),
      tags: ['developer', 'author']
    },
    {
      name: 'demos',
      disabled: false,
      onClick: () => window.open('https://www.eesewiki.ford.com/pages/viewpage.action?pageId=1434169414'),
      tags: ['developer', 'author']
    },
    { name: 'embed', disabled: false, onClick: () => props.navigateForward!('/embed-dynamic-pages'), tags: ['author'] },
    { name: 'structure', disabled: false, onClick: () => props.navigateForward!('/aem-structure'), tags: ['developer', 'author'] },
    { name: 'host-assembler', disabled: false, onClick: () => props.navigateForward!('/provisioning-assembler'), tags: ['developer'] },
    { name: 'assembler-quickstart', disabled: false, onClick: () => props.navigateForward!('/assembler-quickstart'), tags: ['developer'] }
    // { name: 'analytics-and-personalization', disabled: true, onClick: () => {}, tags: ['author'] }
  ];

  const [selectedTag, setSelectedTag] = useState('');
  const [selectedCards, setSelectedCards] = useState(tutorialCards);

  useEffect(() => {
    if (location.hash) {
      const div = document.querySelector(location.hash);
      div?.scrollIntoView();
    }
  }, [location.hash]);

  function getCards(tag: string) {
    setSelectedCards(tutorialCards.filter((tutorial) => tutorial.tags.includes(tag) || tag.length === 0));
    setSelectedTag(tag);
  }

  return (
    <AEMPage pagePath={process.env.AEM_CONTENT_BASE} aemHost={getAEMHost()}>
      <StyledHeader>
        <Text name="header" />
      </StyledHeader>
      <Page style={{ position: 'relative' }}>
        <PageContent style={{ justifyContent: 'center', position: 'relative' }}>
          <div style={{ margin: '15px 0' }}>
            <Text name="why-are-you-here" />
          </div>
          <div style={{ width: '100%', display: 'flex', gap: 50 }}>
            <Card name="build-a-site" onClick={() => props.navigateForward!('/new-page')} />
            <Card name="build-a-tile" onClick={() => props.navigateForward!('/dynamic-page')} />
          </div>
          <div id="access-container" style={{ margin: '5px 0 70px 0', width: '100%', scrollMargin: '30px' }}>
            <Text name="credential-message" />
            <AccessLinkContainer>
              <div className="access-link">
                <EditableButton name="wiki-access" onClick={() => window.open('https://www.accessmgmt.ford.com/CspsWeb/cspsHomePre.do')} />
                <Text name="wiki-access-info" />
              </div>
              <div className="access-link">
                <EditableButton
                  name="aem-access"
                  onClick={() =>
                    window.open(
                      'https://forms.office.com/pages/responsepage.aspx?id=eruQyfRRm0O9NpwH-xBBwCSNN8_iX31NoSEJhloQlw9UMU4xN0FVM1hTNDlQWDY1T0JCR0xKSVYzSS4u'
                    )
                  }
                />
                <Text name="aem-access-info" />
              </div>
            </AccessLinkContainer>
          </div>
          <FilterContainer>
            <Text className="instruction-text" name="filter" />
            <EditableButton className={selectedTag === '' ? 'bold' : ''} name="all" onClick={() => getCards('')} />
            <EditableButton className={selectedTag === 'developer' ? 'bold' : ''} name="developer" onClick={() => getCards('developer')} />
            <EditableButton className={selectedTag === 'author' ? 'bold' : ''} name="author" onClick={() => getCards('author')} />
          </FilterContainer>
          <StyledGrid id="tutorials">
            {selectedCards.map((tutorial, index) => {
              return (
                <CardCell
                  className={`${selectedCards.length % 2 === 1 && index === selectedCards.length - 1 ? 'last-card' : ''}`}
                  key={`${tutorial.name}-card`}>
                  <Card className="cell" {...tutorial} />
                </CardCell>
              );
            })}
          </StyledGrid>
        </PageContent>
      </Page>
    </AEMPage>
  );
};

export default withAnimatedNavigation(Home);
